import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToastNotificationService {
  private TOAST_DURATION: number = 5000;

  constructor(private toastr: ToastrService) {}

  error(message: string, timeOut?: number | undefined) {
    this.toastr.error("Error", message, {
      timeOut: timeOut ? timeOut : this.TOAST_DURATION,
      progressBar: true,
      progressAnimation: "decreasing",
    });
  }

  success(message: string, timeOut?: number | undefined) {
    this.toastr.success("Success", message, {
      timeOut: timeOut ? timeOut : this.TOAST_DURATION,
      progressBar: true,
      progressAnimation: "decreasing",
    });
  }
}
