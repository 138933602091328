import { AuthGuard } from "./authentication/auth.guard";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "admin",
    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: "logistics",
    loadChildren: () =>
      import("./logistics-dashboard/logistics-dashboard.module").then(
        (m) => m.LogisticsDashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "authentication",
    loadChildren: () =>
      import("./authentication/authentication.module").then((m) => m.AuthenticationModule),
  },
  {
    path: "",
    redirectTo: "authentication",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
