import { environment } from "./../../environments/environment";
import { AuthService } from "./../services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { ToastNotificationService } from "../services/toast-service";
import { AdminService } from "../services/admin.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _cookieService: CookieService,
    private _authService: AuthService,
    private _toast: ToastNotificationService,
    private _adminService: AdminService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const exemptUrls = [
      `${environment.base_url}admin/login`,
      `${environment.base_url}admin/forgot`,
    ];

    if (exemptUrls.indexOf(request.url) > -1) {
      const hreq = request.clone({ setHeaders: { Accept: "application/json" } });
      return next.handle(hreq);
    }

    const token = this._authService.fetchToken();

    const authReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });

    return next.handle(authReq).pipe(
      filter((event) => event instanceof HttpResponse),
      tap(
        (event) => {},
        (error) => {
          const requestMethod = request.method;
          if (error.error.message === "Unauthorized") {
            localStorage.removeItem("adminInfo");
            localStorage.removeItem("token");
            this._cookieService.delete("token", "/");
            this._authService.isAuthenticated.next(false);
            window.location.reload();
          }

          if (error.status === 403) {
            if (requestMethod === "GET") {
              this._adminService.hasPermission$.next(true);
            } else {
              this._toast.error("YOU ARE NOT AUTHORIZED TO PERFORM THIS ACTION", 5000);
            }
          }
        }
      )
    );
  }
}
