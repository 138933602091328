import { DefaultResponse } from "./../interfaces/default.response";
import { AdminUser } from "./../interfaces/admin.interface";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AdminService } from "./admin.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  isAuthenticated = new BehaviorSubject<boolean>(null);
  userProfile = new BehaviorSubject<AdminUser>(null);
  redirectUrl: string;

  constructor(
    private _cookieService: CookieService,
    private _http: HttpClient,
    private _adminService: AdminService
  ) {}

  adminLogin(email: string, password: string) {
    return this._http
      .post<DefaultResponse>(
        `${environment.base_url}admin/login`,
        { email, password },
        {
          headers: { Accept: "application/json" },
        }
      )
      .pipe(
        tap((res) => {
          if (res.status) {
            this.adminLoginData(res);
          }
        }),
        map((res) => {
          const roles = res.user.roles.map((role) => role.name);
          const dashboardView = this.handleDashboardView(roles);
          const response = {
            ...res,
            dashboardView,
          };
          return response;
        })
      );
  }

  sendPasswordRecoveryEmail(email: string) {
    return this._http.post<DefaultResponse>(`${environment.base_url}admin/forgot-password`, {
      email,
    });
  }

  resetAdminPassword(token, payload) {
    return this._http.post<DefaultResponse>(
      `${environment.base_url}admin/reset-password/${token}`,
      payload
    );
  }

  adminLoginData(loginResponse: DefaultResponse) {
    this.isAuthenticated.next(true);
    this.userProfile.next(loginResponse.user);
    localStorage.setItem("adminInfo", JSON.stringify(loginResponse.user));
    this._cookieService.set("token", loginResponse.token, null, "/");

    const { roles } = loginResponse.user;
    const rolesArray = roles.map((role) => role.name);
    this.handleDashboardView(rolesArray);
  }

  handleDashboardView(roles): "admin" | "la" {
    let dashboardView: "admin" | "la" = "admin";
    if (roles.includes("Super Admin")) {
      dashboardView = "admin";
    } else if (roles.includes("Logistics Analyst")) {
      dashboardView = "la";
    } else {
      dashboardView = "admin";
    }

    localStorage.setItem("loginType", dashboardView);
    this._adminService.loginType$.next(dashboardView);

    return dashboardView;
  }

  isAccessTokenValid() {
    const accessToken = this._cookieService.get("token");
    if (accessToken) {
      this.userProfile.next(JSON.parse(localStorage.getItem("adminInfo")));
      this.isAuthenticated.next(true);
    }
  }

  fetchToken() {
    return this._cookieService.get("token");
  }

  handleLogout() {
    localStorage.removeItem("adminInfo");
    localStorage.removeItem("loginType");
    this._cookieService.delete("token", "/");
    this.userProfile.next(null);
    this.isAuthenticated.next(false);
    window.location.reload();
  }
}
